import { createMuiTheme } from "@material-ui/core";

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#df7c32",

      light: "rgb(82,54,78)",

      contrastText: "#ffffff",
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#df7c32",

      light: "rgb(82,54,78)",

      contrastText: "#ffffff",
    },
  },
});
