import Utils from ".";

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
  if (token) {
    Utils.constants.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    Utils.constants.axios.defaults.headers.common["Authorization"] = "";
    delete Utils.constants.axios.defaults.headers.common["Authorization"];
  }
};

export const getAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }

  return accessToken;
};

/**
 * get user id form localStorage
 */
export const getUserIdToken = () => {
  return localStorage.getItem("accessToken");
};
