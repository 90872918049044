import Utils from "../../utils";
import { HealthScanQuestionsModel } from "../../model";

export const healthScanQuestionsReducer = (
  state: HealthScanQuestionsModel = new HealthScanQuestionsModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.MY_HEALTH)
    return { ...state, ...action.payload };
  return state;
};
