import {
  PrivateChatModel,
  GroupChatModel,
  ChatHistoryModel,
} from "../../model";
import Utils from "../../utils";

export const privateChatReducer = (
  state: PrivateChatModel = new PrivateChatModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.PRIVATE_CHAT: {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
};

export const groupChatReducer = (
  state: GroupChatModel = new GroupChatModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GROUP_CHAT: {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
};

export const chatHistoryReducer = (
  state: ChatHistoryModel = new ChatHistoryModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.CHAT_HISTORY: {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
};
