import {
  BirthPlanAnswerModel,
  BirthPlanModel,
  BirthQuestionsModel,
} from "../../model";
import Utils from "../../utils";

export const birthPlanReducer = (
  state: BirthPlanModel = new BirthPlanModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BIRTH_CATEGORY)
    return { ...state, ...action.payload };
  return state;
};

export const birthQuestionsReducer = (
  state: BirthQuestionsModel = new BirthQuestionsModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BIRTH_QUESTIONS)
    return { ...state, ...action.payload };
  return state;
};

export const birthPlanAnswerReducer = (
  state: BirthPlanAnswerModel = new BirthPlanAnswerModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BIRTH_ANSWERS)
    return { ...state, ...action.payload };
  return state;
};
