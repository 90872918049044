export default {
  login: "/admin/login",
  adminRegistation: "/admin/register",
  deleteAdmin: "/admin/delete-admin",

  coachRegistation: "/admin/coach-add",
  coachList: "/admin/coachList",
  bulkUsers: "/v1/admin/assignCoach",

  assignedUsers: "/v1/admin/userByCoachId",
  unassignedUsers: "/v1/admin/userList",

  adminList: "/admin",
  refreshToken: "/admin/accessToken",
  users: "/admin/userList",
  verifyOtp: "/admin/verifyOtp",
  loginOtp: "/admin/loginOtp",
  resetPassword: "/admin/resetPassword",
  forgotPassword: "/admin/forgetPassword",
  userDetail: "/admin/user",
  addUser: "/admin/addUser",
  uploadImage: "/common/upload",

  //content management endpoints
  getBlogDetails: "/admin/blog",
  blog: "/admin/blog",
  allBlog: "/admin/allBlog",

  //static content endpoints
  staticContent: "/admin/staticContent",

  // expert management endpoints
  expertCategory: "/admin/expertCategory",
  addExpertCategory: "/admin/add-expertCategory",
  deleteExpertCategory: "/admin/delete-expertCategory/",
  updateExpertCategory: "/admin/update-expertCategory",
  expert: "/admin/expert",
  addExpert: "/admin/add-expert",
  deleteExpert: "/admin/delete-expert/",
  updateExpert: "/admin/update-expert",

  // assign coach
  assignCoach: "/admin/assignCoach",
  updateCoachProfile: "/admin/updateCoachProfile",
  updateUserDetails: "/admin/updateUserDetails",

  //birth plan
  birthPlan: "/admin/birthPlan",
  birthQuestion: "/admin/birthPlanQuestion",
  birthPlanAnswer: "/v1/admin/birthPlanAnswer",
  sendBirthPlanReport: "/v1/admin/birthPlanReport",

  //community-management
  groupDetail: "/admin/groupDetail",
  group: "/admin/group",
  groupMember: "/admin/member",
  userNotInGroup: "/admin/userNotInGroup",
  coachNotInGroup: "/admin/coachNotInGroup",

  //Journey Management
  event: "/admin/event",

  // event users
  eventUsers: "/admin/eventUser",

  //health scan Management
  myHealth: "/admin/myHealth",

  // user bulk upload
  userBulkUpload: "/admin/bulk-upload",
  userDownloadTemplate: "/admin/download-template",

  // email verify
  verifyUserEmail: "/users/verifyEmail",

  // birthPlanReport
  birthPlanReport: "/admin/birthPlanReport",

  // backupCoach
  assignBackupCoach: "/admin/assignBackupCoach",

  // user & coach history
  userHistory: "/admin/userHistory",

  coachHistory: "/admin/patientHistory",

  // logout
  adminLogout: "/admin/logout",

  // dashboard
  dashboard: "/admin/dashboard",

  // news and Promotions
  newsAndPromotions: "/admin/newsAndPromotion",

  // week management
  weekManagement: "/admin/weekDescription",

  // chat management
  chatRecords: "/admin/inbox",

  chatSearch: "/admin/chatSearch",

  chatPage: "/admin/chat",

  deleteChat: "/v1/admin/chat",

  // audio management

  audioBlog: "/admin/audio-blog",

  // blood pressure

  bpDevice: "/admin/withings/device-list",

  addDevice: "/withing/link-device",

  unassignDevice: "/withing/unlink-device",

  tutorialList: "/tutorial/list",

  tutorialCreate: "/tutorial/create",

  tutorialUpdate: "/tutorial/update",

  userReadings: "/withing/admin-user-data/list",

  deleteDevices: "/withing/device",

  usersWithDevices: "/withing/admin-bp-measurement/list",

  // export data pull

  bpDataPull: "/admin/download/bp-data",

  healthScanPull: "/admin/download/health-scan",

  loginDataPull: "/admin/download/loginData",

  content: "/v1/admin/download/contentData",
};
