import {
  getAccessToken,
  getUserIdToken,
  setAuthorizationToken,
} from "./session";

import axios from "axios";

const apiURLBase = process.env.REACT_APP_BASE_URL;

const contentfulLink = process.env.REACT_APP_CONTENTFUL_LINK;

const $axios = axios.create({
  baseURL: apiURLBase,
  headers: {
    "Content-Type": "application/json",
  },
});

const api_error_code = {
  unauthorized: 401,
  accessDenied: 430,

  sessionExpired: 419,
  validationError: 400,
  emailNotVerified: 403,
};

const api_success_code = {
  postSuccess: 201,
  success: 200,
};

const table_actions = {
  SEARCH: "search",
  CHANGE_PAGE: "changePage",
  ON_SEARCH_CLOSE: "onSearchClose",
  CHANGE_ROWS_PER_PAGE: "changeRowsPerPage",
};

const paths = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
};

const routeConstants = {
  forgotPassword: "/forgot-password",
  twoFA: "/2fa",
  resetPassword: "/reset-password",
  otp: "/otp",
  emailVerficationStatus: "/email-verification-status",
  underDevelopment: "/under-development",
  dashboard: "/dashboard",
  coachList: "/guide-list",
  admin: "/admin",
  userList: "/user-management",
  expertManagement: "/expert-management",
  staticContent: "/manage-static-content",
  contentManagement: "/content-management",
  communityManagement: "/community-management",
  communityMember: "/community-management/:communityId",
  journeyManagement: "/event-management",
  birthCategory: "/birth-plan/question-answers/:birthCategory",
  birthPlan: "/birth-plan",
  birthPlanAns: "/birth-plan/users/:userId",
  myHealth: "/my-health",
  backupCoach: "/manage-backup-guide",
  activityHistory: "/user-and-guide-history/:userid",
  eventUsers: "/event-management/private-events/:eventId",
  newsAndPromotions: "/news-and-promotions",
  weekManagement: "/week-management",
  adminList: "/admin-list",
  chatManagement: "/chat-management",
  viewBirthPlan: "/view-birth-plan/:userId",
  chatHistory: "/chat-management/:chatType/:groupId",
  audioManagement: "/audio-management",
  bloodPressure: "/blood-pressure-management",
  userReadings: "/user-readings",
  reportManagement: "/report-management",
  bulkUsers: "/guide-list/:guideId",
};

const showAlertSeconds = 4;
/**
 * constant variable for the website
 */
const constant = {
  paths,
  apiUrl: apiURLBase,
  axios: $axios,
  table_actions,
  getUserIdToken,
  getAccessToken,
  api_error_code,
  api_success_code,
  setAuthorizationToken,
  showAlertSeconds,
  routeConstants,
  contentfulLink,
};
export default constant;
