import Utils from "../../utils";
import { AudioContentModel } from "../../model";

export const audioContentReducer = (
  state: AudioContentModel = new AudioContentModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.AUDIO_CONTENT) {
    return { ...state, ...action.payload };
  }
  return { ...state };
};
