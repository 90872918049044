import Utils from "../../utils";
import { CoachModel, UnassignedUsers, AssignedUsers } from "../../model";

export const coachReducer = (
  state: CoachModel = new CoachModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.COACH)
    return { ...state, ...action.payload };
  return state;
};

export const assignedUsersReducer = (
  state: AssignedUsers = new AssignedUsers(),
  action: any
) => {
  if (action.type === Utils.ActionName.A_USERS)
    return { ...state, ...action.payload };
  return state;
};

export const unassignedUsersReducer = (
  state: UnassignedUsers = new UnassignedUsers(),
  action: any
) => {
  if (action.type === Utils.ActionName.U_USERS)
    return { ...state, ...action.payload };
  return state;
};
