import {
  Button,
  ListItem,
  Theme,
  createStyles,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

import React from "react";

// Component for Navigation Items

const NavItem = ({
  href,
  icon: Icon,
  title,
  active,
  divider,
  miniDrawer,
  ...rest
}: any) => {
  const styles = makeStyles((_theme: Theme) =>
    createStyles({
      btn: {
        width: "100%",
        height: 35,
        padding: "0 24px",
        "& span": {
          justifyContent: "flex-start",
          fontSize: 11,
          marginLeft: 0,
        },
      },
      navItemName: {
        color: miniDrawer ? "transparent" : "#515151",
      },
      avatar: {
        "& img": {
          objectFit: "contain",
        },
      },
    })
  );

  const classes = styles();
  const navAttribute = {
    disableGutters: true,
    style: active
      ? {
          background: "whitesmoke",
        }
      : null,
    ...rest,
  };

  const icon = () => {
    if (Icon && typeof Icon === "string") {
      return (
        <Avatar
          src={Icon}
          alt={"icon"}
          variant="square"
          style={{ width: 20, height: 20 }}
          className={classes.avatar}
        />
      );
    }
    return <Icon size={"20"} />;
  };

  return (
    <React.Fragment>
      <ListItem {...navAttribute}>
        <NavLink to={href} style={{ width: "100%" }}>
          <Button className={classes.btn} startIcon={icon()}>
            <span className={classes.navItemName}>{title}</span>
          </Button>
        </NavLink>
      </ListItem>
      {divider && <div style={{ borderBottom: "3px solid whitesmoke" }} />}
    </React.Fragment>
  );
};

export default NavItem;
