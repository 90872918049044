import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Container from "../components/container";

import Utils from "../utils";

interface Props {
  path: string;
  component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: Utils.ActionName.IS_LOADING,
      payload: false,
    });
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <React.Fragment>
          {Utils.constants.getUserIdToken() ? (
            <Container {...props}>
              <Suspense fallback={""}>
                <Component {...props} />
              </Suspense>
            </Container>
          ) : (
            <Redirect to="/" />
          )}
        </React.Fragment>
      )}
    />
  );
};
export default PrivateRoute;
