import { lazy } from "react";

import constant from "./constants";

const Admin = lazy(() => import("../screens/admin"));
const UserList = lazy(() => import("../screens/users"));
const TWOFA = lazy(() => import("../screens/login/2FA"));
const CoachList = lazy(() => import("../screens/coaches"));
const BulkUsers = lazy(() => import("../screens/coaches/bulkUsers"));
const Dashboard = lazy(() => import("../screens/dashboard"));
const BackupCoach = lazy(() => import("../screens/backupCoaches"));
const BirthPlanQandA = lazy(() => import("../screens/birthPlanQ&A"));
const ForgotPassword = lazy(() => import("../screens/forgotPassword"));
const WeekManagement = lazy(() => import("../screens/weekManagement"));
const HealthScanQuestions = lazy(() => import("../screens/healthScan"));
const UnderDevelopment = lazy(() => import("../screens/underDevelopment"));
const ExpertManagement = lazy(() => import("../screens/expertManagement"));
const NewsAndPromotions = lazy(() => import("../screens/newAndPromotions"));
const CommunityManagement = lazy(() => import("../screens/peerManagement"));
const ContentManagement = lazy(() => import("../screens/contentManagement"));
const JourneyManagement = lazy(() => import("../screens/journeyManagement"));
const forgotPasswordOtp = lazy(() => import("../screens/forgotPassword/otp"));
const ActivityHistory = lazy(() => import("../screens/users/activityHistory"));

const ResetPassword = lazy(
  () => import("../screens/forgotPassword/resetPassword")
);
const ManageStaticContent = lazy(
  () => import("../screens/manangeStaticContent")
);
const EventUsers = lazy(
  () => import("../screens/journeyManagement/eventUsers")
);
const BirthPlanQuestions = lazy(
  () => import("../screens/birthPlanQ&A/birthPlanQuestions")
);
const PeerManagement = lazy(
  () => import("../screens/peerManagement/communityMembers")
);
const AdminManagement = lazy(() => import("../screens/admin"));

const ChatManagement = lazy(() => import("../screens/chatManagement"));

const ChatHistory = lazy(() => import("../screens/chatManagement/chatHistory"));

const AudioManagement = lazy(() => import("../screens/audioManagement"));

const BloodPressureManagement = lazy(() => import("../screens/bloodPressure"));

const UserReadings = lazy(
  () =>
    import("../screens/bloodPressure/bloodPressureMeasurements/userReadings")
);

const ViewBirthPlan = lazy(
  () => import("../screens/birthPlanQ&A/viewBirthPlan")
);

const ReportManagement = lazy(() => import("../screens/reportManagement"));

const {
  routeConstants: {
    forgotPassword,
    twoFA,
    resetPassword,
    otp,
    underDevelopment,
    dashboard,
    coachList,
    admin,
    userList,
    expertManagement,
    staticContent,
    contentManagement,
    communityManagement,
    communityMember,
    journeyManagement,
    birthCategory,
    birthPlan,
    myHealth,
    backupCoach,
    activityHistory,
    eventUsers,
    newsAndPromotions,
    weekManagement,
    adminList,
    chatManagement,
    chatHistory,
    audioManagement,
    bloodPressure,
    userReadings,
    birthPlanAns,
    reportManagement,
    bulkUsers,
  },
} = constant;

export const routes = [
  { path: forgotPassword, component: ForgotPassword, type: 0 },
  { path: twoFA, component: TWOFA, type: 0 },
  { path: resetPassword, component: ResetPassword, type: 0 },
  { path: otp, component: forgotPasswordOtp, type: 0 },
  { path: underDevelopment, component: UnderDevelopment, type: 1 },
  { path: dashboard, component: Dashboard, type: 1 },
  { path: adminList, component: AdminManagement, type: 1 },
  { path: bulkUsers, component: BulkUsers, type: 1 },
  { path: coachList, component: CoachList, type: 1 },
  { path: admin, component: Admin, type: 1 },
  { path: userList, component: UserList, type: 1 },
  { path: expertManagement, component: ExpertManagement, type: 1 },
  { path: staticContent, component: ManageStaticContent, type: 1 },
  { path: contentManagement, component: ContentManagement, type: 1 },
  { path: communityMember, component: PeerManagement, type: 1 },
  { path: communityManagement, component: CommunityManagement, type: 1 },
  { path: eventUsers, component: EventUsers, type: 1 },
  { path: journeyManagement, component: JourneyManagement, type: 1 },
  { path: birthCategory, component: BirthPlanQuestions, type: 1 },
  { path: birthPlanAns, component: ViewBirthPlan, type: 1 },
  { path: birthPlan, component: BirthPlanQandA, type: 1 },
  { path: myHealth, component: HealthScanQuestions, type: 1 },
  { path: backupCoach, component: BackupCoach, type: 1 },
  { path: activityHistory, component: ActivityHistory, type: 1 },
  { path: newsAndPromotions, component: NewsAndPromotions, type: 1 },
  { path: chatHistory, component: ChatHistory, type: 1 },
  { path: chatManagement, component: ChatManagement, type: 1 },
  { path: reportManagement, component: ReportManagement, type: 1 },
  { path: weekManagement, component: WeekManagement, type: 1 },
  { path: audioManagement, component: AudioManagement, type: 1 },
  { path: bloodPressure, component: BloodPressureManagement, type: 1 },
  { path: userReadings, component: UserReadings, type: 1 },
];
