import React, { Suspense } from "react";

import { Route, Redirect } from "react-router-dom";
import AuthContainer from "../components/authContainer";

import Utils from "../utils";

interface Props {
  path: string;
  component: any;
}
const Router = ({ component: Component, path }: Props) => {
  return (
    <React.Fragment>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Suspense
            fallback={
              <div>
                <span>Loading....</span>
              </div>
            }
          >
            <React.Fragment>
              {Utils.constants.getUserIdToken() &&
              window.location.pathname !== "/email-verification-status" ? (
                <Redirect to="/user-management" />
              ) : (
                <AuthContainer>
                  <Component {...props} />
                </AuthContainer>
              )}
            </React.Fragment>
          </Suspense>
        )}
      />
    </React.Fragment>
  );
};

export default Router;
