/* LIBRARIES */
import { useSelector } from "react-redux";
import React, { lazy, Suspense } from "react";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

/* COMPONENTS */
import Utils from "../utils";
import Public from "./public";
import PrivateRoute from "./private";
import { routes } from "../utils/routes";
import AuthContainer from "../components/authContainer";
import { lightTheme, darkTheme } from "../components/themeSetting";

/* MODELS */
import { ReducersModel } from "../model";

/* ----------------------------------  Routing Main Page ----------------------------------- */

const ViewBirthPlan = lazy(() => import("../screens/viewBirthPlan"));
const Login = lazy(() => import("../screens/login"));
const PageNotFound = lazy(() => import("../screens/pageNotFound"));
const EmailVerificationStatus = lazy(
  () => import("../screens/emailVerficationStatus")
);

const Router: React.FC = () => {
  const { selectTheme } = useSelector(
    (state: ReducersModel) => state.themeReducer
  );

  return (
    <ThemeProvider theme={selectTheme === "light" ? lightTheme : darkTheme}>
      <BrowserRouter>
        <Switch>
          {routes?.map((item, index) => {
            if (item.type === 0) {
              return (
                <Public
                  path={item.path}
                  component={item.component}
                  key={index}
                />
              );
            }
            return (
              <PrivateRoute
                path={item.path}
                component={item.component}
                key={index}
              />
            );
          })}

          <Route
            exact
            path="/"
            render={(props: any) => (
              <Suspense
                fallback={
                  <div>
                    <span>Loading....</span>
                  </div>
                }
              >
                <React.Fragment>
                  {Utils.constants.getUserIdToken() ? (
                    <Redirect to="/user-management" />
                  ) : (
                    <AuthContainer>
                      <Login {...props} />
                    </AuthContainer>
                  )}
                </React.Fragment>
              </Suspense>
            )}
          />

          <Route
            exact
            path="/email-verification-status"
            render={(props: any) => (
              <Suspense
                fallback={
                  <div>
                    <span>Loading....</span>
                  </div>
                }
              >
                <React.Fragment>
                  <AuthContainer>
                    <EmailVerificationStatus {...props} />
                  </AuthContainer>
                </React.Fragment>
              </Suspense>
            )}
          />

          <Route
            exact
            path="/view-birth-plan/:userId"
            render={(props: any) => (
              <Suspense
                fallback={
                  <div>
                    <span>Loading....</span>
                  </div>
                }
              >
                <React.Fragment>
                  <AuthContainer>
                    <ViewBirthPlan {...props} />
                  </AuthContainer>
                </React.Fragment>
              </Suspense>
            )}
          />
          <Route
            exact
            render={(props: any) => (
              <Suspense
                fallback={
                  <div>
                    <span>Loading....</span>
                  </div>
                }
              >
                <React.Fragment>
                  <AuthContainer>
                    <PageNotFound {...props} />
                  </AuthContainer>
                </React.Fragment>
              </Suspense>
            )}
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Router;
