class LoadingModel {
  isLoading: boolean = true;
  mainLoading: boolean = false;
}

class SignInModel {
  username: any = localStorage.getItem("rememberMe")
    ? localStorage.getItem("email")
    : "";
  password: any = localStorage.getItem("rememberMe")
    ? localStorage.getItem("password")
    : "";
  accountError: string = "";
  rememberMe: boolean = localStorage.getItem("rememberMe") === "1";
  otp: any = "";
  isEmailVerified: boolean = false;
}
class DrawerModel {
  toggle: any = false;
}

class CoachModel {
  coachData: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
  coachId = "";
  status: any = "null";
  startDate: any = null;
  endDate: any = null;
  isActive = false;

  selectedId: any = "";
  selectedFirstName: any = "";
  selectedLastName: any = "";
  selectedEmail: any = "";
  selectedPhoneNo: any = "";
  selectedDob: any = "";
  selectePassword: any = "";
  selectedCityAndState: any = "";
  selectedDisplayName: any = "";
  selectedCoachImage: any = "";
  isEdit: boolean = false;

  coachDataAll: any = [];
}

class UnassignedUsers {
  limit: number = 100;
  page: number = 1;
  count: number = 0;
  users: [] = [];
  searchKey: string = "";
}

class AssignedUsers {
  limit: number = 100;
  page: number = 1;
  count: number = 0;
  users: [] = [];
  searchKey: string = "";
}

class ThemeModel {
  selectTheme: any =
    localStorage.getItem("selectTheme") === null
      ? "light"
      : localStorage.getItem("selectTheme");
}

class UserListModel {
  usersList: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
  userDetail: any = {};
  status: any = "null";
  startDate: any = null;
  endDate: any = null;
  isProfileComplete: any = false;
  isActive = false;

  selectedId: any = "";
  isEdit: boolean = false;
  assignedCoachId: any = "";

  firstName: any = "";
  lastName: any = "";
  phoneNo: any = "";
  isShowOnboardingQuestions: any = "";
  dueDate: any = "";
  multiplePregnant: any = "";
  cSection: any = "";
  dob: any = "";
  cityAndState: any = "";
  displayName: any = "";

  socialSecurityNumber: any = "";
  gender: any = "";
  race: any = "";
  ethnicity: any = "";
  preferredLanguage: any = "";
  emergencyContactName: any = "";
  emergencyContactRelation: any = "";
  emergencyContactPhone: any = "";
  primaryCareProviderName: any = "";
  guarantorName: any = "";
  guarantorGender: any = "";
  guarantorDOB: any = "";
  gurantorSocialSecurityNumber: any = "";
  gurantorContactNumber: any = "";
  insuaranceSubscriberName: any = "";
  insuaranceID: any = "";
  insuaranceGroupID: any = "";
  actualDueDate: any = null;
  actualDeliveryDate: string = "";
  createdAt: string = "";
}

class AdminListModel {
  adminListData: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
}
class ForgotPasswordModel {
  otp: any = "";
}

class StaticContentModel {
  termsAndConditions: any = null;
  privacyPolicy: any = null;
  aboutUs: any = null;
  helpSection: any = null;
}

class ExpertCategoryListModel {
  categoryList: any = [];
  categoryListAll: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
  userDetail: any = {};
  status: any = "null";
  startDate: any = null;
  endDate: any = null;
  priority: string = "";
  isActive = false;
  selectedId: any = "";
  selectedName: any = "";
  selectedDescription: any = "";
  selectedImage: any = "";
  selectedStatus: any = "";
  isEdit: boolean = false;
}

class ExpertListModel {
  expertList: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
  userDetail: any = {};
  status: any = "null";
  startDate: any = null;
  endDate: any = null;
  priority: string = "";

  isActive = false;
  selectedId: any = "";
  selectedExperience: any = "";
  selectedFirstName: any = "";
  selectedLastName: any = "";
  selectedEmail: any = "";
  selectedCityAndState: any = "";

  selectedImage: any = "";
  selectedBio: any = "";
  selectedCategoryId: any = "";
  isEdit: boolean = false;
  url: string = "";
}

class ArticleModel {
  articleList: any = [];
  count = 0;
  page = 1;
  limit = 100;
  search = "";
  status = "null";
  startDate: any = null;
  endDate: any = null;
}

class ArticleDetailModel {
  id: string = "";
  status: string = "null";
  isEdit: boolean = false;
  blogid: string = "";
}

class EventListModel {
  publicEventList: [] = [];
  privateEventList: [] = [];
  eventId: string = "";
  title: string = "";
  description: string = "";
  image: string = "";
  icon: string = "";
  startTime: string = "";
  endTime: string = "";
  eventDate: string = "";
  eventLink: string = "";
  status: string = "null";
  editStatus: string = "null";
  page: number = 1;
  limit: number = 100;
  count = 0;
  startDate: any = null;
  endDate: any = null;
  search = "";
  isEdit: boolean = false;
  blog_id: string = "";
  isPrivate: boolean = false;
}

class EventUsersModel {
  usersList: [] = [];
  eventUsersIds: [] = [];
  page: number = 1;
  limit: number = 100;
  count: number = 0;
}

class CommunityModel {
  membersList: [] = [];
  groupId: string = "";
  groupName: string = "";
  icon: string = "";
  description: string = "";
  page: number = 1;
  limit: number = 100;
  count: number = 0;
  status: string = "null";
  startDate: any = null;
  endDate: any = null;
  search: string = "";
  createdAt: string = "";
  userNotInGroup: [] = [];
  coachNotInGroup: [] = [];
}

class CommunityDetailModel {
  communityList: [] = [];
  communityId: string = "";
  communityName: string = "";
  communityDesc: string = "";
  communityIcon: string = "";
  editStatus: string = "null";
  search: string = "";
  startDate: any = null;
  endDate: any = null;
  count: number = 0;
  page: number = 1;
  limit: number = 100;
  isEdit: boolean = false;
}

class BirthPlanModel {
  categoryList: [] = [];
  categoryName: string = "";
  categoryDesc: string = "";
  status: string = "null";
  editStatus: string = "null";
  search: string = "";
  startDate: any = null;
  endDate: any = null;
  count: number = 0;
  page: number = 1;
  limit: number = 100;
  isEdit: boolean = false;
  categoryId: string = "";
  priority: number = 0;
}

class BirthQuestionsModel {
  questionId: string = "";
  questionsList: [] = [];
  blogList: [] = [];
  question: string = "";
  description: string = "";
  questionType: number = 0;
  optionArray: [] = [];
  isOther: boolean = false;
  blog_id: string = "";
  status: string = "null";
  editStatus: string = "null";
  count: number = 0;
  page: number = 1;
  search: string = "";
  limit: number = 100;
  isEdit: boolean = false;
  priority: number = 0;
}

class BirthPlanAnswerModel {
  answers: [] = [];
}

class HealthScanQuestionsModel {
  questionsList: [] = [];
  questionId: string = "";
  question: string = "";
  description: string = "";
  questionType: number = 0;
  optionArray: [] = [];
  alertAnswer: [] = [];
  start_week: number = 0;
  end_week: number = 0;
  priority: number = 0;
  status: string = "null";
  editStatus: string = "null";
  count: number = 0;
  page: number = 1;
  search: string = "";
  limit: number = 100;
  startWeek: any = null;
  endWeek: any = null;
  isEdit: boolean = false;
  isPostpartum: boolean = false;
  question_title: string = "";
}

class BulkUploadModel {
  invalidRecord: any = 0;
  totalRecord: any = 0;
  validRecord: any = 0;
  errorAtRow: any = 0;
  duplicateRecord = "";
  duplicateOrderRecords: any = 0;
  duplicatePackageRecords: any = 0;
  duplicateShipmentRecords: any = 0;
  totalOrderRecords: any = 0;
  totalPackageRecords: any = 0;
  totalShipmentRecords: any = 0;
  nameOfFile = "";
  wholeFile: any;
  bulkUploadError = "";
  bulkUploadSuccessMessage = "";
}
class BackupCoachListModel {
  backupCoachStartDate: string = "";
  backupCoachEndDate: string = "";
  backupCoach: any = null;
  selectedId: any = "";
  isEdit: boolean = false;
}

class BirthPlanReportModel {
  reportData: [] = [];
  firstname: string = "";
  lastname: string = "";
}

class UserHistoryModel {
  activities: [] = [];
  limit: number = 100;
  page: number = 1;
  count: number = 0;
}

class CoachHistoryModel {
  activities: [] = [];
  limit: number = 100;
  page: number = 1;
  count: number = 0;
}

class DashboardModel {
  dashboardData: any = null;
}

class NewsAndPromotionsModel {
  newsAndPromotions: [] = [];
  count: number = 0;
  limit: number = 100;
  page: number = 1;
  search: string = "";
  startDate: any = null;
  endDate: any = null;
  topic: string = "";
  description: string = "";
  image: string = "";
  isEdit: boolean = false;
  newsId: string = "";
}

class WeekManagementModel {
  weekDescription: [] = [];
  count: number = 0;
  page: number = 1;
  limit: number = 100;
}

class PrivateChatModel {
  search: string = "";
  page: number = 1;
  limit: number = 100;
  count: number = 0;
  records: [] = [];
}

class GroupChatModel {
  search: string = "";
  page: number = 1;
  limit: number = 100;
  count: number = 0;
  records: [] = [];
}

class ChatHistoryModel {
  search: string = "";
  chats: [] = [];
  page: number = 1;
  limit: number = 0;
  count: number = 0;
  pageCount: number = 0;
  loader: boolean = false;
  previewNumber: number = 0;
  previewData: any = null;
  startSearch: boolean = false;
  chatIdSearched: string = "";
  article: { [key: string]: any } | null = null;
  totalSearchCount: number = 0;
  searchIds: string[] = [];
  searchCount: number = 0;
}

class AudioContentModel {
  page: number = 1;
  count: number = 0;
  audioList: [] = [];
  limit: number = 100;
  endDate: any = null;
  status: any = "null";
  startDate: any = null;
  searchKey: string = "";

  isEdit: boolean = false;
  topicForm: any = null;
}

/** BP Management Models */

class BPDeviceModel {
  page: number = 1;
  count: number = 0;
  limit: number = 100;
  searchKey: string = "";
  devices: [] = [];
  usersList: [] = [];
  userSearch: string = "";
  mac: string = "";
  user: { [key: string]: any } = {};
  deviceIsEdit: boolean = false;
}

class BPUserModel {
  data: [] = [];
  page: number = 1;
  count: number = 0;
  limit: number = 100;
  searchKey: string = "";
}

class BPReadingsModel {
  readings: [] = [];
  page: number = 1;
  count: number = 0;
  limit: number = 100;
  searchKey: string = "";
}

class BPInstructionModel {
  page: number = 1;
  count: number = 0;
  limit: number = 100;
  instructionVideos: [] = [];
  isEdit: boolean = false;
  videoItem: { id: string; title: string; url: string } = {
    id: "",
    title: "",
    url: "",
  };
}

class ReducersModel {
  globalLoaderReducer: LoadingModel = new LoadingModel();
  signInReducer: SignInModel = new SignInModel();
  coachReducer: CoachModel = new CoachModel();
  unassignedUsersReducer: UnassignedUsers = new UnassignedUsers();
  assignedUsersReducer: AssignedUsers = new AssignedUsers();
  forgotPasswordReducer: ForgotPasswordModel = new ForgotPasswordModel();
  themeReducer: ThemeModel = new ThemeModel();
  userListReducer: UserListModel = new UserListModel();
  adminReducer: AdminListModel = new AdminListModel();
  drawerReducer: DrawerModel = new DrawerModel();
  staticContentReducer: StaticContentModel = new StaticContentModel();
  expertCategoryListReducer: ExpertCategoryListModel = new ExpertCategoryListModel();
  expertListReducer: ExpertListModel = new ExpertListModel();
  articleReducer: ArticleModel = new ArticleModel();
  articleDetailReducer: ArticleDetailModel = new ArticleDetailModel();
  eventListReducer: EventListModel = new EventListModel();
  communityReducer: CommunityModel = new CommunityModel();
  communityDetailReducer: CommunityDetailModel = new CommunityDetailModel();
  birthPlanReducer: BirthPlanModel = new BirthPlanModel();
  birthQuestionsReducer: BirthQuestionsModel = new BirthQuestionsModel();
  BulkUploadReducer: BulkUploadModel = new BulkUploadModel();
  healthScanQuestionsReducer: HealthScanQuestionsModel = new HealthScanQuestionsModel();
  birthPlanReportReducer: BirthPlanReportModel = new BirthPlanReportModel();
  backupCoachListReducer: BackupCoachListModel = new BackupCoachListModel();
  userHistoryReducer: UserHistoryModel = new UserHistoryModel();
  coachHistoryReducer: CoachHistoryModel = new CoachHistoryModel();
  dashboardReducer: DashboardModel = new DashboardModel();
  eventUsersReducer: EventUsersModel = new EventUsersModel();
  newsAndPromotionsReducer: NewsAndPromotionsModel = new NewsAndPromotionsModel();
  weekManagementReducer: WeekManagementModel = new WeekManagementModel();
  privateChatReducer: PrivateChatModel = new PrivateChatModel();
  groupChatReducer: GroupChatModel = new GroupChatModel();
  chatHistoryReducer: ChatHistoryModel = new ChatHistoryModel();
  audioContentReducer: AudioContentModel = new AudioContentModel();
  bpDeviceReducer: BPDeviceModel = new BPDeviceModel();
  bpUserReducer: BPUserModel = new BPUserModel();
  bpReadingsReducer: BPReadingsModel = new BPReadingsModel();
  bpVideosReducer: BPInstructionModel = new BPInstructionModel();
  birthPlanAnswerReducer: BirthPlanAnswerModel = new BirthPlanAnswerModel();
}

export {
  SignInModel,
  LoadingModel,
  ReducersModel,
  ThemeModel,
  CoachModel,
  UnassignedUsers,
  AssignedUsers,
  UserListModel,
  AdminListModel,
  ForgotPasswordModel,
  DrawerModel,
  StaticContentModel,
  ExpertListModel,
  ExpertCategoryListModel,
  ArticleModel,
  ArticleDetailModel,
  EventListModel,
  CommunityModel,
  CommunityDetailModel,
  BirthPlanModel,
  BirthQuestionsModel,
  BulkUploadModel,
  HealthScanQuestionsModel,
  BirthPlanReportModel,
  BackupCoachListModel,
  UserHistoryModel,
  CoachHistoryModel,
  DashboardModel,
  EventUsersModel,
  NewsAndPromotionsModel,
  WeekManagementModel,
  PrivateChatModel,
  GroupChatModel,
  ChatHistoryModel,
  AudioContentModel,
  BPDeviceModel,
  BPUserModel,
  BPReadingsModel,
  BPInstructionModel,
  BirthPlanAnswerModel,
};
