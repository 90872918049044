import Utils from "../../utils";

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("otpToken");
  localStorage.removeItem("adminId");
  Utils.constants.setAuthorizationToken();

  window.location.reload();
};

export const logoutApp = (history: any) => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: Utils.ActionName.IS_LOADING,
      payload: true,
    });

    if (!navigator.onLine) {
      Utils.showAlert(2, "Please check your internet connection!");
      dispatch({
        type: Utils.ActionName.IS_LOADING,
        payload: false,
      });
      return;
    }

    Utils.api.getApiCall(
      Utils.endPoints.adminLogout,
      "",
      (respData: any) => {
        const { data } = respData;
        if (data.statusCode === Utils.constants.api_success_code.success) {
          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });
          logout();
          history.push("/");
        } else {
          Utils.showAlert(2, data.message);

          dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: false,
          });
        }
      },
      (error: any) => {
        const { data } = error;
        Utils.showAlert(2, data.message);

        dispatch({
          type: Utils.ActionName.IS_LOADING,
          payload: false,
        });
      }
    );
  };
};
