import Utils from "../../utils";
import { EventListModel } from "../../model";

export const eventListReducer = (
  state: EventListModel = new EventListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_EVENT)
    return { ...state, ...action.payload };
  return state;
};
