import Utils from "../../utils";
import { StaticContentModel } from "../../model";

export const staticContentReducer = (
  state: StaticContentModel = new StaticContentModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.GET_TERMS_AND_CONDITIONS:
      return { ...state, termsAndConditions: action.payload };
    case Utils.ActionName.GET_PRIVACY_POLICY:
      return { ...state, privacyPolicy: action.payload };
    case Utils.ActionName.GET_ABOUT_US:
      return { ...state, aboutUs: action.payload };
    case Utils.ActionName.GET_HELP:
      return { ...state, helpSection: action.payload };
    default:
      return state;
  }
};
