import {
  Database as AdminIcon,
  AlertCircle as AlertCircleIcon,
  Archive as BackupCoachIcon,
  BarChart as BarChartIcon,
  Calendar,
  PlusCircle as CoachIcon,
  FileText,
  PlusSquare as HealthIcon,
  Image,
  List as ListIcon,
  MessageCircle,
  Tv as TvIcon,
  User as UserIcon,
  Users as UsersIcon,
  MessageSquare as ChatIcon,
  Music as AudioIcon,
  Columns as ReportIcon,
} from "react-feather";
import {
  Avatar,
  Drawer,
  List,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NavItem from "./NavItem";
import Utils from "../../utils";
import clsx from "clsx";
import LocalImages from "../../utils/images";

// Component for SideBar Navigation

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "calc(100vh - 64px)",
    position: "fixed",
    zIndex: 1,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11) + 1,
    },
  },
  container: {
    paddingTop: 64,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      boxShadow: "inset 0 0 6px lightgrey",
    },
  },
  imgContainer: {
    background: "var(--peach)",
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    zIndex: 1,
    top: 0,
    "& img": {
      width: 60,
    },
  },
  navItems: {
    overflowX: "hidden",
  },
  navFooter: {
    width: "100%",
    display: "flex",
    borderBottom: "3px solid whitesmoke",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "white",
    padding: "10px 16px",
    borderTop: "1px solid lightgrey",
    animation: "all 1s ease-in",
    overflowX: "hidden",
  },
  adminName: {
    marginLeft: 20,
  },
}));

const items = [
  {
    href: "/dashboard",
    icon: BarChartIcon,
    title: "Dashboard",
  },
  {
    href: "/admin-list",
    icon: AdminIcon,
    title: "Admin Management",
  },
  {
    href: "/user-management",
    icon: UsersIcon,
    title: "User/Patient Management",
  },
  {
    href: "/guide-list",
    icon: CoachIcon,
    title: "Guide Management",
  },
  {
    href: "/expert-management",
    icon: UserIcon,
    title: "Experts Network Management",
  },
  {
    href: "/blood-pressure-management",
    icon: LocalImages.HeartRate,
    title: "Blood Pressure Management",
    image: true,
  },
  {
    href: "/community-management",
    icon: MessageCircle,
    title: "Community Management",
  },
  {
    href: "/birth-plan",
    icon: AlertCircleIcon,
    title: "Birth Preferences Q & A",
  },
  {
    href: "/my-health",
    icon: HealthIcon,
    title: "Health Scan Questions Management",
  },
  {
    href: "/manage-backup-guide",
    icon: BackupCoachIcon,
    title: "Assign Backup Guide",
  },
  {
    href: "/audio-management",
    icon: AudioIcon,
    title: "Audio Management",
  },
  {
    href: "/chat-management",
    icon: ChatIcon,
    title: "Chat Management",
  },
  {
    href: "/report-management",
    icon: ReportIcon,
    title: "Report Management",
  },
  {
    href: "/news-and-promotions",
    icon: TvIcon,
    title: "News & Promotions",
  },
  {
    href: "/week-management",
    icon: ListIcon,
    title: "Week Management",
  },
  {
    href: "/content-management",
    icon: Image,
    title: "Article Management",
  },
  {
    href: "/event-management",
    icon: Calendar,
    title: "Event Management",
  },

  {
    href: "/manage-static-content",
    icon: FileText,
    title: "Static Content Management",
  },
];

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Senior Developer",
  name: "Admin",
};

export default function SidePanel() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { toggle } = useSelector((state: any) => state.drawerReducer);

  const handleEnter = () => {
    dispatch({
      type: Utils.ActionName.GET_DRAWER,
      payload: { toggle: true },
    });
  };

  const handleLeave = () => {
    dispatch({
      type: Utils.ActionName.GET_DRAWER,
      payload: { toggle: false },
    });
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: toggle,
        [classes.drawerClose]: !toggle,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: toggle,
          [classes.drawerClose]: !toggle,
        }),
      }}
    >
      <div className={classes.container}>
        <div className={classes.navFooter}>
          <Avatar
            component={RouterLink}
            src={"user.avatar"}
            to={"/admin-List"}
            style={{ backgroundColor: "#df7c32" }}
          />
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.adminName}
            style={!toggle ? { color: "transparent" } : { color: "black" }}
          >
            {user.name}
          </Typography>
        </div>

        <div className={`${classes.navItems}`}>
          <List onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
            {items.map((item: any) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                active={window.location.pathname.includes(item.href)}
                miniDrawer={!toggle}
                divider={item.href === "/report-management"}
              />
            ))}
          </List>
        </div>
      </div>
    </Drawer>
  );
}
