import { useSelector } from "react-redux";
import {
  makeStyles,
  createStyles,
  Theme,
  Backdrop,
  Box,
} from "@material-ui/core";
import React from "react";

import SidePanel from "../sidepanel";
import Header from "../../components/header/header";
import Spinner from "../spinner";
import { ReducersModel } from "../../model";

/* Componnet for overAll screen Container */

const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: { position: "relative" },
    section: { display: "flex" },
    rightContainer: {
      width: "calc(100%)",
      height: "calc(100vh - 64px)",
      overflow: "scroll",
      marginLeft: theme.spacing(11) + 1,
      backgroundColor: "var(--background-grey)",
      "&::-webkit-scrollbar": {
        display: "none !important",
      },
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

export default function Container({ children }: any) {
  const classes = styles();
  const { isLoading } = useSelector(
    (state: ReducersModel) => state.globalLoaderReducer
  );

  return (
    <div className={classes.mainContainer}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <Box>
          <Spinner />
        </Box>
      </Backdrop>
      <Header />
      <div className={classes.section}>
        <SidePanel />
        <div className={classes.rightContainer}>{children}</div>
      </div>
    </div>
  );
}
