import Utils from "../../utils";
import { CommunityModel, CommunityDetailModel } from "../../model";

export const communityReducer = (
  state: CommunityModel = new CommunityModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_GROUP_MEMBER)
    return { ...state, ...action.payload };
  return state;
};

export const communityDetailReducer = (
  state: CommunityDetailModel = new CommunityDetailModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_GROUP)
    return { ...state, ...action.payload };
  return state;
};
