import { ThemeModel } from "../../model";

export const themeReducer = (
  state: ThemeModel = new ThemeModel(),
  action: any
) => {
  if (action.type === "UPDATE_THEME_VALUE")
    return { ...state, ...action.payload };
  return state;
};
