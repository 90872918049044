import Utils from "../../utils";
import { DrawerModel } from "../../model";

export const drawerReducer = (
  state: DrawerModel = new DrawerModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_DRAWER)
    return { ...state, ...action.payload };
  return state;
};
