import Utils from "../../utils";
import { SignInModel } from "../../model";

export const signInReducer = (
  state: SignInModel = new SignInModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.SIGN_IN)
    return { ...state, ...action.payload };
  return state;
};
