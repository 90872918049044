import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import React from "react";
import utils from "../../utils";

// Component for showing Toastify alerts

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 10px",
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
    },
    rightContainer: {
      display: "flex",
      alignItems: "center",
    },
    message: {
      marginLeft: "5px",
      textAlign: "left",
      font: "normal normal normal 14px/20px Roboto",
      letterSpacing: "0.1px",
      color: "#FFFFFF",
      opacity: 1,
    },
    remainingTime: {
      marginRight: "5px",
    },
  })
);

type AlertProps = {
  icon?: any;
  message?: string;
  style?: any;
  close: Function;
};

export default function Alert({ icon, message, style, close }: AlertProps) {
  const classes = useStyles();
  const ref = React.useRef<any>(null);
  const [seconds, setSeconds] = React.useState(
    utils.constants.showAlertSeconds
  );

  React.useEffect(() => {
    if (seconds > 0) setTimeout(() => setSeconds(seconds - 1), 1000);
    else {
      close();
      if (ref) ref.current.click();
    }
  }, [seconds, close]);

  return (
    <div className={classes.container} style={style} ref={ref}>
      <div className={classes.leftContainer}>
        {icon} <span className={classes.message}>{message}</span>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.remainingTime}>{seconds}s</div>
        <CloseIcon />
      </div>
    </div>
  );
}
