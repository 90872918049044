import Utils from "../../utils";
import { BirthPlanReportModel } from "../../model";

export const birthPlanReportReducer = (
  state: BirthPlanReportModel = new BirthPlanReportModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.BIRTH_PLAN_REPORT:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
