export default {
  SIGN_IN: "SIGN_IN",
  LOADING: "LOADING",

  COACH: "COACH",
  U_USERS: "U_USERS",
  A_USERS: "A_USERS",

  ADMIN_LIST: "ADMIN_LIST",
  IS_LOADING: "IS_LOADING",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  GET_USERS_LIST: "GET_USERS_LIST",
  GET_DRAWER: "GET_DRAWER",

  //GET_STATIC_CONTENT

  GET_TERMS_AND_CONDITIONS: "GET_TERMS_AND_CONDITIONS",
  GET_PRIVACY_POLICY: "GET_PRIVACY_POLICY",
  GET_ABOUT_US: "GET_ABOUT_US",
  GET_HELP: "GET_HELP",

  //EXPERT_MANAGEMENT

  GET_EXPERTS_CATEGORIES: "GET_EXPERTS_CATEGORIES",
  GET_EXPERTS: "GET_EXPERTS",

  //CONTENT MANAGEMENT

  GET_ARTICLES: "GET_ARTICLES",
  ARTICLE_DETAIL: "ARTICLE_DETAIL",
  BULK_UPLOAD: "BULK_UPLOAD",
  EDIT_ARTICLE: "EDIT_ARTICLE",

  //BIRTH_PLAN_MANAGEMENT

  BIRTH_CATEGORY: "BIRTH_CATEGORY",
  BIRTH_QUESTIONS: "BIRTH_QUESTIONS",
  BIRTH_ANSWERS: "BIRTH_ANSWERS",

  //Community Management
  GET_GROUP: "GET_GROUP",
  GET_GROUP_MEMBER: "GET_GROUP_MEMBER",

  //Journey Management
  GET_EVENT: "GET_EVENT",

  // Event users
  EVENT_USERS: "EVENT_USERS",

  // health scan Management
  MY_HEALTH: "MY_HEALTH",

  // View Birth Plan Report
  BIRTH_PLAN_REPORT: "BIRTH_PLAN_REPORT",
  // backup coach management
  BACKUP_COACH: "BACKUP_COACH",

  // user UserAnalytic
  USER_HISTORY: "USER_HISTORY",

  COACH_HISTORY: "COACH_HISTORY",

  // dashborad
  DASHBOARD: "DASHBOARD",

  // news and promotions
  NEWSANDPROMOTION: "NEWSANDPROMOTION",

  // week management
  WEEK_MANAGEMENT: "WEEK_MANAGEMENT",

  // chat management
  PRIVATE_CHAT: "PRIVATE_CHAT",

  GROUP_CHAT: "GROUP_CHAT",

  CHAT_HISTORY: "CHAT_HISTORY",

  // audio management
  AUDIO_CONTENT: "AUDIO_CONTENT",

  // blood pressure
  BP_DEVICES: "BP_DEVICES",

  BP_USER: "BP_USER",

  BP_READINGS: "BP_READINGS",

  BP_VIDEOS: "BP_VIDEOS",
};
