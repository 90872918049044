import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import React from "react";

/* Component for Theme Filled Button */

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      "& .MuiButtonBase-root": {
        background: "var(--peach)",
        borderRadius: "4px",
        font: "normal normal normal 16px/20px Roboto",
        letterSpacing: "0.06px",
        color: "white",
        textTransform: "uppercase",
        opacity: 1,
        height: "36px",
        minWidth: 100,
      },
      "& .Mui-disabled": { backgroundColor: "#cccccc !important" },
    },
  })
);

type MultimediaButtonProps = {
  text: string;
  icon?: string;
  onClick?: any;
  isDisabled?: any;
  type?: any;
  size?: "small" | "large" | "medium";
};

export default function MultimediaButton({
  icon,
  text,
  onClick,
  isDisabled = false,
  type = "submit",
  size = "large",
}: MultimediaButtonProps) {
  const classes = useStyles();

  return (
    <div id="filledThemeBtn" className={classes.root}>
      <Button
        disabled={isDisabled ? isDisabled : false}
        onClick={onClick ? onClick : () => {}}
        size={size}
        type={type}
        color="primary"
        fullWidth
        startIcon={
          icon ? (
            <img src={icon} alt={"upload"} />
          ) : (
            <React.Fragment></React.Fragment>
          )
        }
      >
        {text}
      </Button>
    </div>
  );
}
