const EYE_OPEN = "/images/Eye Icon.svg";
const EYE_CLOSE = "/images/Icon ionic-md-eye-off.svg";
const SEARCH_ICON = "/images/Group 1535.svg";
const CANCEL = "/images/cancel.svg";
const INVALID_IMAGE = "/images/invalidImagePlaceholder.png";
const CAMERA_SMALL = "/images/camera.png";
const LOGO = "/images/Millie_Logo_White_RGB 1millie.svg";
const LOGO_BLACK = "/images/logo.png";
const STAMPED_ICON = "/images/stamped.png";
const LOGO_NEW = "/images/logo_updated.png";
const LOGO_NEW2 = "/images/app_icon_ochre.jpeg";
const NoImage = "/images/noImage.png";
const HeartRate = "/images/heart-rate.svg";

const LocalImages = {
  EYE_OPEN,
  EYE_CLOSE,
  SEARCH_ICON,
  CANCEL,
  INVALID_IMAGE,
  CAMERA_SMALL,
  LOGO,
  STAMPED_ICON,
  LOGO_BLACK,
  LOGO_NEW,
  LOGO_NEW2,
  NoImage,
  HeartRate,
};

export default LocalImages;
