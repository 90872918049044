import Utils from "../../utils";
import {
  UserListModel,
  BulkUploadModel,
  UserHistoryModel,
  CoachHistoryModel,
} from "../../model";

export const userListReducer = (
  state: UserListModel = new UserListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_USERS_LIST)
    return { ...state, ...action.payload };
  return state;
};

export const BulkUploadReducer = (
  state: BulkUploadModel = new BulkUploadModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BULK_UPLOAD)
    return { ...state, ...action.payload };
  return state;
};

export const userHistoryReducer = (
  state: UserHistoryModel = new UserHistoryModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.USER_HISTORY)
    return { ...state, ...action.payload };
  return state;
};

export const coachHistoryReducer = (
  state: CoachHistoryModel = new CoachHistoryModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.COACH_HISTORY)
    return { ...state, ...action.payload };
  return state;
};
