import Utils from "../../utils";
import { ExpertCategoryListModel, ExpertListModel } from "../../model";

export const expertCategoryListReducer = (
  state: ExpertCategoryListModel = new ExpertCategoryListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_EXPERTS_CATEGORIES)
    return { ...state, ...action.payload };
  return state;
};

export const expertListReducer = (
  state: ExpertListModel = new ExpertListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_EXPERTS)
    return { ...state, ...action.payload };
  return state;
};
