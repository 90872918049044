import Utils from "../../utils";
import { AdminListModel } from "../../model";

export const adminReducer = (
  state: AdminListModel = new AdminListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.ADMIN_LIST)
    return { ...state, ...action.payload };
  return state;
};
