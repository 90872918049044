import Utils from "../../utils";
import { BackupCoachListModel } from "../../model";

export const backupCoachListReducer = (
  state: BackupCoachListModel = new BackupCoachListModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BACKUP_COACH)
    return { ...state, ...action.payload };
  return state;
};
