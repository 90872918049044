import { makeStyles, createStyles, Theme } from "@material-ui/core";

import React from "react";

// Component AuthContainer is Wrapper for Public Urls

const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      minHeight: "100vh",
      maxHeight: "100vh",
      backgroundColor: "#F7F7FB",
    },
  })
);

type AuthProps = {
  children?: React.ReactNode;
};

export default function AuthContainer({ children }: AuthProps) {
  const classes = styles();
  return <div className={classes.mainContainer}>{children}</div>;
}
