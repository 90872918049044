import Utils from "../../utils";
import { NewsAndPromotionsModel } from "../../model";

export const newsAndPromotionsReducer = (
  state: NewsAndPromotionsModel = new NewsAndPromotionsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.NEWSANDPROMOTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
