import { IconButton, Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import InputIcon from "@material-ui/icons/Input";
import LocalImages from "../../utils/images";
import LogoutPopup from "./logoutPopup";
import SimpleModal from "../../components/modal";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: "#df7c32",
      width: "100%",
      height: "64px",
      display: "flex",
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      zIndex: 2,
      "& svg": { color: "white" },
    },

    flexboxRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    flexContainer: {
      display: "flex",
      justifyContent: "space-around",
    },

    leftContainer: {
      marginLeft: "-1px",
    },

    imgContainer: {
      height: "64px",
      width: "89px",
      borderRadius: "50%",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    imgStyl: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  })
);

export default function AdminHeader({ isPublic = false }: any) {
  const classes = styles();

  const [open, setOpen] = useState(false);

  const [islogoutIcon, setLogoutIcon] = useState(false);

  React.useEffect(() => {
    if (!isPublic) {
      setLogoutIcon(localStorage.getItem("accessToken") ? true : false);
    }
  }, [isPublic]);

  const headerLogo = () => {
    return (
      <div className={classes.imgContainer}>
        <img
          className={classes.imgStyl}
          alt="header-logo"
          src={LocalImages.LOGO_NEW2}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        <div className={`${classes.flexContainer} ${classes.leftContainer}`}>
          {headerLogo()}
        </div>

        {islogoutIcon ? (
          <IconButton color="inherit" onClick={() => setOpen(true)}>
            <InputIcon />
          </IconButton>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
      <SimpleModal
        isOpen={open}
        handleClose={() => setOpen(false)}
        style={classes.flexboxRow}
        body={
          <LogoutPopup
            handleClose={() => {
              setOpen(false);
            }}
          />
        }
      />
    </React.Fragment>
  );
}
