import Utils from "../../utils";
import { WeekManagementModel } from "../../model";

export const weekManagementReducer = (
  state: WeekManagementModel = new WeekManagementModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.WEEK_MANAGEMENT:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
