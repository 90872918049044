import Utils from "../../utils";
import { ForgotPasswordModel } from "../../model";

export const forgotPasswordReducer = (
  state: ForgotPasswordModel = new ForgotPasswordModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.FORGOT_PASSWORD)
    return { ...state, ...action.payload };
  return state;
};
