import Utils from "../../utils";
import {
  BPDeviceModel,
  BPUserModel,
  BPReadingsModel,
  BPInstructionModel,
} from "../../model";

export const bpDeviceReducer = (
  state: BPDeviceModel = new BPDeviceModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BP_DEVICES)
    return { ...state, ...action.payload };

  return state;
};

export const bpUserReducer = (
  state: BPUserModel = new BPUserModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BP_USER)
    return { ...state, ...action.payload };

  return state;
};

export const bpReadingsReducer = (
  state: BPReadingsModel = new BPReadingsModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BP_READINGS)
    return { ...state, ...action.payload };

  return state;
};

export const bpVideosReducer = (
  state: BPInstructionModel = new BPInstructionModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.BP_VIDEOS)
    return { ...state, ...action.payload };

  return state;
};
