import { makeStyles, createStyles, Theme, Button } from "@material-ui/core";
import React from "react";

/* Component for Transparent Button  */

const styles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      margin: "0 15px",
      minWidth: "100px",
      textAlign: "center",
      font: "normal normal medium 16px/20px Roboto",
      letterSpacing: "0.06px",
      color: "#515151",
      opacity: 1,
      height: "36px",
    },
  })
);

type TransparentButtonProps = {
  handleClose?: any;
  disabled?: boolean;
  text?: string;
  size?: "small" | "large";
};

const TransparentButton = ({
  handleClose,
  disabled = false,
  size = "large",
  text = "",
}: TransparentButtonProps) => {
  const classes = styles();

  return (
    <Button
      variant="outlined"
      size={size}
      className={classes.cancelButton}
      onClick={handleClose}
      disabled={disabled}
    >
      {text ? text : "CANCEL"}
    </Button>
  );
};
export default TransparentButton;
