import Utils from "../../../utils";
import { EventUsersModel } from "../../../model";

export const eventUsersReducer = (
  state: EventUsersModel = new EventUsersModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.EVENT_USERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
