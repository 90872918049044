import Utils from "../../utils";
import { ArticleDetailModel, ArticleModel } from "../../model";

export const articleReducer = (
  state: ArticleModel = new ArticleModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.GET_ARTICLES)
    return { ...state, ...action.payload };
  return state;
};

export const articleDetailReducer = (
  state: ArticleDetailModel = new ArticleDetailModel(),
  action: any
) => {
  if (action.type === Utils.ActionName.EDIT_ARTICLE)
    return { ...state, ...action.payload };
  return state;
};
