import { DashboardModel } from "../../model";
import Utils from "../../utils";

export const dashboardReducer = (
  state: DashboardModel = new DashboardModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.DASHBOARD:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
