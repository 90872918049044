import {
  BulkUploadReducer,
  userListReducer,
  userHistoryReducer,
  coachHistoryReducer,
} from "../screens/users/reducer";
import {
  articleDetailReducer,
  articleReducer,
} from "../screens/contentManagement/reducer";
import {
  birthPlanReducer,
  birthQuestionsReducer,
  birthPlanAnswerReducer,
} from "../screens/birthPlanQ&A/reducer";
import {
  communityDetailReducer,
  communityReducer,
} from "../screens/peerManagement/reducer";
import {
  expertCategoryListReducer,
  expertListReducer,
} from "../screens/expertManagement/reducer";
import { signInReducer } from "../screens/login/reducer";
import { themeReducer } from "../components/themeSetting/reducer";
import { adminReducer } from "../screens/admin/reducer";
import { backupCoachListReducer } from "../screens/backupCoaches/reducer";
import { birthPlanReportReducer } from "../screens/viewBirthPlan/reducer";
import {
  coachReducer,
  assignedUsersReducer,
  unassignedUsersReducer,
} from "../screens/coaches/reducer";
import { combineReducers } from "redux";
import { drawerReducer } from "../components/header/reducer";
import { eventListReducer } from "../screens/journeyManagement/reducer";
import { forgotPasswordReducer } from "../screens/forgotPassword/reducer";
import { globalLoaderReducer } from "./rootReducer";
import { healthScanQuestionsReducer } from "../screens/healthScan/reducer";
import { staticContentReducer } from "../screens/manangeStaticContent/reducer";
import { dashboardReducer } from "../screens/dashboard/reducer";
import { eventUsersReducer } from "../screens/journeyManagement/eventUsers/reducer";
import { newsAndPromotionsReducer } from "../screens/newAndPromotions/reducer";
import { weekManagementReducer } from "../screens/weekManagement/reducer";
import {
  privateChatReducer,
  groupChatReducer,
  chatHistoryReducer,
} from "../screens/chatManagement/reducer";
import { audioContentReducer } from "../screens/audioManagement/reducer";
import {
  bpDeviceReducer,
  bpReadingsReducer,
  bpUserReducer,
  bpVideosReducer,
} from "../screens/bloodPressure/reducer";

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  signInReducer,
  drawerReducer,
  globalLoaderReducer,
  themeReducer,
  coachReducer,
  assignedUsersReducer,
  unassignedUsersReducer,
  adminReducer,
  userListReducer,
  forgotPasswordReducer,
  staticContentReducer,
  expertListReducer,
  expertCategoryListReducer,
  articleReducer,
  articleDetailReducer,
  BulkUploadReducer,
  birthPlanReducer,
  birthQuestionsReducer,
  communityReducer,
  communityDetailReducer,
  eventListReducer,
  healthScanQuestionsReducer,
  birthPlanReportReducer,
  backupCoachListReducer,
  userHistoryReducer,
  coachHistoryReducer,
  dashboardReducer,
  eventUsersReducer,
  newsAndPromotionsReducer,
  weekManagementReducer,
  privateChatReducer,
  groupChatReducer,
  chatHistoryReducer,
  audioContentReducer,
  bpDeviceReducer,
  bpReadingsReducer,
  bpUserReducer,
  bpVideosReducer,
  birthPlanAnswerReducer,
});

export default rootReducer;
