import { Theme, Typography, createStyles, makeStyles } from "@material-ui/core";

import React from "react";

import SaveButton from "../../components/buttons";
import { logoutApp } from "./action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TransparentButton from "../buttons/transparentButton";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "25%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    mainContainer: {
      padding: "25px 0",
      "& .MuiTextField-root": {
        marginBottom: "10px!important",
      },
    },

    heading: {
      font: "normal normal normal 20px/20px Roboto",
      letterSpacing: "0.07px",
      color: "#515151",
      opacity: 1,
      margin: "15px 0 28px 0",
      textAlign: "center",
    },

    btnDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      "&>div:nth-child(2)": {
        width: theme.spacing(13),
        marginLeft: theme.spacing(1),
      },
    },
  })
);

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "90vh",
    overflow: "auto",
  };
}

function LogoutPop({ handleClose }: any) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainer}>
        <Typography className={classes.heading}>
          Are you sure, you want to logout?
        </Typography>

        <div className={classes.btnDiv}>
          <TransparentButton handleClose={handleClose} />
          <SaveButton
            onClick={() => {
              dispatch(logoutApp(history));
              handleClose();
            }}
            text="Ok"
          />
        </div>
      </div>
    </div>
  );
}

export default LogoutPop;
