import axios from "axios";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

function setAuthorizationToken() {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("accessToken")}`;
}

const getPagewiseIndex = (page: number, index: number, limit: number) => {
  return limit * (page - 1) + index + 1;
};

const getModalStyle = (height: string) => {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: `${height}`,
    overflow: "auto",
  };
};

const getWeeks = (week: number = 70) => {
  let weeksArray = [];
  for (let i = 1; i <= week; i++) {
    weeksArray.push(`${i}`);
  }
  return weeksArray;
};

const validateFile = (fileObj: File, size: number) => {
  if (fileObj && fileObj.size < size * 1024 * 1024) {
    return true;
  }
  return false;
};

const checkImageExt = (url: string) => {
  return (
    [".jpg", ".png", ".jpeg"].filter((val) => url?.includes(val)).length > 0
  );
};

const trimester = (arr: []) => {
  if (arr && arr.length > 0) {
    let res = new Set(
      arr.map((val: any) => {
        if (val >= 1 && val <= 11) return "First Trimester";
        else if (val >= 12 && val <= 26) return "Second Trimester";
        else if (val >= 27) return "Third Trimester";
        return "";
      })
    );
    return Array.from(res).toString().split(",").join(" | ");
  }
  return "-";
};

function paramsToObject(entries: any) {
  const result: { [key: string]: any } = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const convertURLParamsToObject = (searchParams: string) => {
  const urlParams = new URLSearchParams(searchParams.replace("?", ""));
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  return params;
};

function computeLength(file: File) {
  return new Promise((resolve, reject) => {
    const objectURL = URL.createObjectURL(file);
    const mySound = new Audio(objectURL);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        const duration = mySound.duration;
        const mins = parseInt("" + duration);
        if (mins > 60 * 60) {
          reject("Max Duration: 60 mins");
        } else {
          resolve(mins);
        }
      },
      false
    );
  });
}

const validateAudioFile = (audioFile: File) => {
  const sizePromise = new Promise((resolve, reject) => {
    const isFileSizeValid = validateFile(audioFile, 50);
    if (!isFileSizeValid) reject("Max Size: 50 Mb");
    else resolve(true);
  });
  const durationPromise = computeLength(audioFile);

  return Promise.all([sizePromise, durationPromise]);
};

export const getColor = (syr: number, dir: number) => {
  const LIGHT_BLUE_TINT = "#BBD2F7";
  const BLUE_TINT_DARK = "#4782e6";
  const MUSTARD_TINT = "#AB9A68";
  const YELLOW_BOX = "#FAECC9";
  const ORANGE_TINT = "#F9C592";
  const ORANGE_DARK_TINT = "#da7d1b";
  const RED_TINT = "#F5BCB0";
  const BRIGHT_RED = "#D93C1B";
  const GREEN_TINTS = "#BFDACE";
  const GREEN_TINT_DARK = "#4C846B";
  if (dir < 90 && syr < 60) {
    return {
      backgroundColor: LIGHT_BLUE_TINT,
      color: BLUE_TINT_DARK,
      text: "Low",
    };
  } else if ((dir >= 90 && dir <= 129) || (syr >= 60 && syr <= 79)) {
    if ((dir >= 130 && dir <= 139) || (syr >= 80 && syr <= 89)) {
      return {
        backgroundColor: YELLOW_BOX,
        color: MUSTARD_TINT,
        text: "Elevated",
      };
    } else if ((dir >= 140 && dir <= 159) || (syr >= 90 && syr <= 109)) {
      return {
        backgroundColor: ORANGE_TINT,
        color: ORANGE_DARK_TINT,
        text: "High Blood Pressure",
      };
    } else if (dir >= 160 || syr >= 110) {
      return {
        backgroundColor: RED_TINT,
        color: BRIGHT_RED,
        text: "Hypertensive Crisis",
      };
    } else {
      return {
        backgroundColor: GREEN_TINTS,
        color: GREEN_TINT_DARK,
        text: "Normal",
      };
    }
  } else if ((dir >= 130 && dir <= 139) || (syr >= 80 && syr <= 89)) {
    if ((dir >= 140 && dir <= 159) || (syr >= 90 && syr <= 109)) {
      return {
        backgroundColor: ORANGE_TINT,
        color: ORANGE_DARK_TINT,
        text: "High Blood Pressure",
      };
    } else if (dir >= 160 || syr >= 110) {
      return {
        backgroundColor: RED_TINT,
        color: BRIGHT_RED,
        text: "Hypertensive Crisis",
      };
    } else {
      return {
        backgroundColor: YELLOW_BOX,
        color: MUSTARD_TINT,
        text: "Elevated",
      };
    }
  } else if ((dir >= 140 && dir <= 159) || (syr >= 90 && syr <= 109)) {
    if (dir >= 160 || syr >= 110) {
      return {
        backgroundColor: RED_TINT,
        color: BRIGHT_RED,
        text: "Hypertensive Crisis",
      };
    } else {
      return {
        backgroundColor: ORANGE_TINT,
        color: ORANGE_DARK_TINT,
        text: "High Blood Pressure",
      };
    }
  } else if (dir >= 160 && syr >= 110) {
    return {
      backgroundColor: RED_TINT,
      color: BRIGHT_RED,
      text: "Hypertensive Crisis",
    };
  }
};

export const convertHtmlStringToEditorState = (htmlString: string) => {
  const blockFromHTML = htmlToDraft(htmlString);
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blockFromHTML.contentBlocks,
      blockFromHTML.entityMap
    )
  );
};

export const convertEditorStateToHtmlString = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent())).toString();
};

const CommonFunctions = {
  setAuthorizationToken,
  getPagewiseIndex,
  getModalStyle,
  getWeeks,
  validateFile,
  checkImageExt,
  trimester,
  convertURLParamsToObject,
  validateAudioFile,
  getColor,
  convertHtmlStringToEditorState,
  convertEditorStateToHtmlString,
};

export default CommonFunctions;
