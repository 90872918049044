import React from "react";
import { Modal } from "@material-ui/core";

/* Component for Simple popup used to display forms */

function SimpleModal({ isOpen, handleClose, body, modStyle }: any) {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={modStyle}
      >
        {body}
      </Modal>
    </div>
  );
}

export default SimpleModal;
